var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search...","type":"text"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('vue-excel-xlsx',{staticClass:"btn btn-primary btn-md",attrs:{"data":_vm.allItems,"columns":_vm.excelColumns,"file-name":'RefurbishItems',"file-type":'xlsx',"sheet-name":'RefurbishItems'}},[_vm._v(" Download Excel Data ")])],1)])],1)],1),_c('b-table',{ref:"refRefurbishedProductListTable",staticClass:"position-relative",attrs:{"id":"RefurbishProductListTableId","items":_vm.allItems,"responsive":"","fields":_vm.refurbishTableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage,"empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(parseInt((_vm.pagination.currentPage - 1) * _vm.pagination.perPage) + data.index + 1)+" ")]}},{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
            name: `product-details`,
            query: { id: data.item.prod_id },
          }},on:{"click":function($event){return _vm.$router.push({
              name: `product-details`,
              query: { id: data.item.prod_id },
            })}}},[_vm._v(" "+_vm._s(data.item.prod_auto_id)+" ")])]}},{key:"cell(item)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.prod_type ? data.item.prod_type.item_type_name : "")+" ")])])]}},{key:"cell(designer)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.prod_designer)+" ")])])]}},{key:"cell(seller_name)",fn:function(data){return [_c('p',[_vm._v(" "+_vm._s(data.item.creator.user_fname + " " + data.item.creator.user_lname)+" ")])]}},{key:"cell(status)",fn:function(data){return [(
            data.item.prod_status == 1 &&
            data.item.prod_status_save_type == 'submit'
          )?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('feather-icon',{attrs:{"size":"18","icon":"MonitorIcon"}})],1),_c('span',[_vm._v("Under Review")])],1):_vm._e(),(
            data.item.prod_status == 2 &&
            data.item.prod_status_save_type == 'submit'
          )?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('feather-icon',{attrs:{"size":"18","icon":"CheckCircleIcon"}})],1),_c('span',[_vm._v("Approved")])],1):_vm._e(),(data.item.prod_status_save_type == 'later')?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('feather-icon',{attrs:{"size":"18","icon":"SaveIcon"}})],1),(data.item.prod_status_save_type == 'later')?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" Saved As Draft ")]):_vm._e()],1):_vm._e()]}},{key:"cell(service)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.prod_customisation))])]}},{key:"cell(date_submitted)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(_vm.getHumanDate(data.item.prod_created_at))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{key:data,staticClass:"mx-1",attrs:{"icon":"EyeIcon","size":"16"}})],1)]}}])}),_c('b-pagination',{attrs:{"aria-controls":"RefurbishProductListTableId","total-rows":_vm.allItems.length,"per-page":_vm.pagination.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }