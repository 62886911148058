<template>
  <div>
    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
          <b-col md="6">
            <div class="custom-search d-flex justify-content-end">
              <vue-excel-xlsx
                :data="allItems"
                :columns="excelColumns"
                :file-name="'RefurbishItems'"
                :file-type="'xlsx'"
                :sheet-name="'RefurbishItems'"
                class="btn btn-primary btn-md"
              >
                Download Excel Data
              </vue-excel-xlsx>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        ref="refRefurbishedProductListTable"
        id="RefurbishProductListTableId"
        :items="allItems"
        responsive
        :fields="refurbishTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(index)="data">
          {{
            parseInt((pagination.currentPage - 1) * pagination.perPage) +
            data.index +
            1
          }}
        </template>

        <template #cell(id)="data">
          <b-link
            :to="{
              name: `product-details`,
              query: { id: data.item.prod_id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `product-details`,
                query: { id: data.item.prod_id },
              })
            "
          >
            {{ data.item.prod_auto_id }}
          </b-link>
        </template>

        <template #cell(item)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{
                data.item.prod_type ? data.item.prod_type.item_type_name : ""
              }}
            </span>
          </b-media>
        </template>

        <template #cell(designer)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.prod_designer }}
            </span>
          </b-media>
        </template>

        <template #cell(seller_name)="data">
          <p>
            {{
              data.item.creator.user_fname + " " + data.item.creator.user_lname
            }}
          </p>
        </template>

        <template #cell(status)="data">
          <div
            class="d-flex align-items-center"
            v-if="
              data.item.prod_status == 1 &&
              data.item.prod_status_save_type == 'submit'
            "
          >
            <b-avatar class="mr-1" variant="light-primary">
              <feather-icon size="18" icon="MonitorIcon" />
            </b-avatar>
            <span>Under Review</span>
          </div>

          <div
            class="d-flex align-items-center"
            v-if="
              data.item.prod_status == 2 &&
              data.item.prod_status_save_type == 'submit'
            "
          >
            <b-avatar class="mr-1" variant="light-primary">
              <feather-icon size="18" icon="CheckCircleIcon" />
            </b-avatar>
            <span>Approved</span>
          </div>

          <div
            class="d-flex align-items-center"
            v-if="data.item.prod_status_save_type == 'later'"
          >
            <b-avatar class="mr-1" variant="light-primary">
              <feather-icon size="18" icon="SaveIcon" />
            </b-avatar>

            <b-badge
              variant="light-primary"
              v-if="data.item.prod_status_save_type == 'later'"
            >
              Saved As Draft
            </b-badge>
          </div>
        </template>

        <template #cell(service)="data">
          <div>{{ data.item.prod_customisation }}</div>
        </template>

        <template #cell(date_submitted)="data">
          <div>
            {{ getHumanDate(data.item.prod_created_at) }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon icon="EyeIcon" size="16" class="mx-1" :key="data" />
          </div>
        </template>
      </b-table>

      <b-pagination
        aria-controls="RefurbishProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="allItems.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import storageService from "@/apiServices/storageService";
import ProductServices from "@/apiServices/ProductServices";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import { refurbishStatus } from "./refurbishStatusCode";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {},
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    allItems() {
      return this.keyword
        ? this.allItemsData.filter((x) =>
            x.prod_designer.includes(this.keyword)
          )
        : this.allItemsData;
    },
  },
  data() {
    return {
      allItemsData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [10, 20, 30],
      },
      sortBy: "index",
      sortDesc: false,
      refurbishTableColumns: [
        "index",
        { key: "id", label: "ID", sortable: true },
        { key: "item", label: "Item name", sortable: true },
        { key: "designer", label: "Designer", sortable: true },
        { key: "seller_name", label: "Seller Name", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "service", label: "Service", sortable: true },
        { key: "date_submitted", label: "Submission Date", sortable: true },
        { key: "actions", label: "Pending Actions" },
      ],
      dynamicExcelColumns: [],
      excelColumns: [
        {
          label: "Item ID",
          field: "prod_auto_id",
        },
        {
          label: "Designer",
          field: "prod_designer",
        },
        {
          label: "Owner Name",
          field: "creator",
          dataFormat: (val) => val.user_fname + " " + val.user_lname,
        },
        {
          label: "Status",
          field: "prod_status",
          dataFormat: (val) => {
            for (let i = 0; i < refurbishStatus.length; i++) {
              const status = refurbishStatus[i];
              if (status.status_code == val) {
                return status.status_message;
              }
            }
            return "";
          },
        },
        {
          label: "Submmission Date",
          field: "prod_created_at",
          dataFormat: (val) => moment(val).format("DD-MM-YYYY"),
        },
        {
          label: "Item Type Name",
          field: "prod_type",
          dataFormat: (val) => (val ? val.item_type_name : ""),
        },
        {
          label: "Owner Email",
          field: "creator.user_email",
        },
        {
          label: "Owner Phone",
          field: "creator.user_phone",
        },
        {
          label: "Base Service Charge",
          field: "product_service_id.service_base_price",
        },
        {
          label: "Toatal Refurbish Cost",
          field: "refurbishtimetine",
          dataFormat: (val) => {
            if (val && val[0]) {
              return (
                val[0].ref_admin_auth_ref_price +
                val[0].ref_admin_auth_ship_cost
              );
            }
            return "";
          },
        },
        {
          label: "Item Pickup Country",
          field: "prod_item_country",
        },
        {
          label: "Item Pickup City",
          field: "prod_item_city",
        },
      ],
    };
  },
  methods: {
    loadAllItems() {
      ProductServices.getRefurbishItems()
        .then((response) => {
          this.allItemsData = response.data.data;
          console.log("List: ", this.allItemsData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
