<template>
  <div>
    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- <b-col md="4" sm="8" class="my-1">
            <b-form-group
              label="Sort"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- All Item table -->
      <b-table
        id="NewProductListTableId"
        ref="refNewProductListTable"
        :items="allItems"
        responsive
        :fields="tableColumns"
        primary-key="prod_id"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        class="position-relative"
      >
        <!-- Column: Id -->
        <template #cell(index)="data">
          {{
            parseInt((pagination.currentPage - 1) * pagination.perPage) +
            data.index +
            1
          }}
        </template>

        <template #cell(id)="data">
          <b-link
            :to="{
              name: `product-details`,
              query: { id: data.item.prod_id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `product-details`,
                query: { id: data.item.prod_id },
              })
            "
          >
            {{ data.item.prod_auto_id }}
          </b-link>
        </template>

        <!-- Column: Client -->
        <template #cell(item_type)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              <!-- {{
                data.item.prod_type && data.item.prod_type.tag_name
                  ? data.item.prod_type.tag_name
                  : null
              }} -->
              {{
                data.item.prod_sub_category ? data.item.prod_sub_category : null
              }}
            </span>
          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.prod_designer }}
            </span>

            <!-- <b-badge
              v-if="data.item.prod_item_type == 'sell'"
              pill
              variant="light-primary"
            >
              {{ data.item.prod_item_type }}
            </b-badge>

            <b-badge
              v-if="data.item.prod_item_type == 'refurbish'"
              pill
              variant="light-primary"
            >
              {{ data.item.prod_item_type }}
            </b-badge> -->
          </b-media>
        </template>

        <template #cell(seller_name)="data">
          <p>
            {{
              data.item.creator.user_fname + " " + data.item.creator.user_lname
            }}
          </p>
        </template>

        <!-- category -->
        <template #cell(status)="data">
          <!-- {{ showSellStatusMessage(parseInt(data.item.prod_status)) }} -->

          <div class="d-flex align-items-center" :key="data">
            <!-- <b-avatar class="mr-1" variant="light-primary">
              <feather-icon size="18" icon="MonitorIcon" />
            </b-avatar> -->
            <span v-if="data.item.prod_item_type == 'sell'">
              {{
                showSellStatusMessage(parseInt(data.item.prod_status))
                  .status_message
              }}
            </span>
            <span v-if="data.item.prod_item_type == 'refurbish'">
              {{
                showRefurbishStatusMessage(parseInt(data.item.prod_status))
                  .status_message
              }}
            </span>
          </div>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(issuedDate)="data">
          <span class="text-nowrap">
            {{ data.item.prod_prod_date }}
          </span>
        </template>

        <template #cell(amount)="data">
          <div>
            {{ data.item.prod_amount ? "£ " + data.item.prod_amount : "N/A" }}
          </div>
        </template>

        <template #cell(date_of_submition)="data">
          <div>
            {{ getHumanDate(data.item.prod_created_at) }}
          </div>
        </template>

        <template #cell(pending_actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon icon="EyeIcon" size="16" class="mx-1" /> -->

            <!-- <b-button
              :to="{
                name: `sell_refurbish`,
                query: { id: data.item.prod_id },
              }"
              class="btn btn-sm ml-1 btn-primary"
              @click="
                $router.push({
                  name: `sell_refurbish`,
                  query: { id: data.item.prod_id },
                })
              "
            >
              Duplicate Item
            </b-button> -->

            <span :key="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.prod_published"
                  @click="deactivate(data.item.prod_id, 0)"
                >
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>Deactivate</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.prod_published"
                  @click="deactivate(data.item.prod_id, 1)"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Activate</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </b-table>

      <b-pagination
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="allItems.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ProductServices from "@/apiServices/ProductServices";
import { refurbishStatus } from "./refurbishStatusCode";
import { sellStatus } from "./sellStatusCode";
import moment from "moment";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {},
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    allItems() {
      return this.keyword
        ? this.allItemsData.filter((item) => {
            if (item.prod_designer) {
              if (
                item.prod_designer
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            }
          })
        : this.allItemsData;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.tableColumns
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  data() {
    return {
      sellStatus: sellStatus,
      refurbishStatus: refurbishStatus,
      sellStatusObj: {},
      refurbishStatusObj: {},

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      allItemsData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [10, 20, 30],
      },
      tableColumns: [
        "index",
        { key: "id", label: "Item ID", sortable: true },
        { key: "item_type", label: "Item Type", sortable: true },
        { key: "client", label: "Designer", sortable: true },
        { key: "seller_name", label: "Seller Name", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "date_of_submition", label: "Submmission Date", sortable: true },
        { key: "pending_actions", label: "Pending Actions" },
      ],
    };
  },
  methods: {
    loadAllItems() {
      console.log("ADMIN_TABLE_LOADED");

      ProductServices.getAllItems()
        .then((response) => {
          this.allItemsData = response.data.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getHumanDate(date) {
      // return (new Date(date)).toDateString();
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    showSellStatusMessage(code) {
      let obj = {};
      const x = this.sellStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.sellStatus[index];
      });
      return obj;
    },

    showRefurbishStatusMessage(code) {
      let obj = {};
      const x = this.refurbishStatus.filter((o, index) => {
        if (o.status_code === code) obj = this.refurbishStatus[index];
      });
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}

.btn-secondary {
  border-color: #a96a37 !important;
  background-color: #a96a37 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
